<!-- Button -->
<button
    mat-icon-button
    [matMenuTriggerFor]="userActions">
    <span class="relative">
        <img
            class="w-7 h-7 rounded-full"
            *ngIf="user?.avatar"
            [src]="user.avatar">
        <mat-icon
            *ngIf="!user?.avatar"
            [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span class="absolute right-0 bottom-0 w-2 h-2 rounded-full"></span>
    </span>
</button>

<mat-menu
    [xPosition]="'before'"
    #userActions="matMenu">
    <a href="{{base}}/intra-empresa "
        mat-menu-item
        >
        <mat-icon svgIcon="heroicons_outline:desktop-computer"></mat-icon>
        <span>Portal</span>
</a>
    <a mat-menu-item href="{{base}}/social/eu/sobre ">
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>Meu Perfil</span>
    </a>
    <a mat-menu-item href="https://guia.vivaintra.com.br/ " target="_blanck">
        <mat-icon svgIcon="heroicons_outline:view-boards"></mat-icon>
        <span>Guia Online</span>
    </a>
    <a mat-menu-item href="https://ajuda.vivaintra.com/" target="_blanck">
        <mat-icon svgIcon="heroicons_outline:question-mark-circle"></mat-icon>
        <span>Central de Ajuda</span>
    </a>
    <a mat-menu-item href="{{base}}/admin-suporte/chamados">
        <mat-icon svgIcon="mat_outline:headset_mic"></mat-icon>
        <span>Suporte</span>
    </a>
    <a mat-menu-item href="{{base}}/intranet/logout ">
        <mat-icon svgIcon="heroicons_outline:logout"></mat-icon>
        <span>Sair</span>
    </a>
</mat-menu>

