<!-- Navigation -->
<fuse-vertical-navigation class="thema print:hidden br:dark" [mode]="isScreenSmall ? 'over' : 'side'" [name]="'mainNavigation'"
    [navigation]="data.navigation.default" [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex items-center w-full p-4 pl-6">
            <!-- Logo -->
            <div class="flex items-center justify-center">
                <img class="w-8" src="assets/images/logo/logo.svg">
            </div>
            <!-- Components -->
        </div>
        <!-- User -->
        <div class="flex flex-col items-center w-full p-4">
            <div class="relative w-24 h-24">
                <img
                class="w-full h-full rounded-full"
                *ngIf="user?.avatar"
                [src]="user.avatar"
                alt="User avatar">
            <mat-icon
                class="icon-size-24"
                *ngIf="!user?.avatar"
                [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
            </div>
            <div class="flex flex-col items-center justify-center w-full mt-6">
                <div
                    class="w-full whitespace-nowrap overflow-ellipsis overflow-hidden text-center leading-normal font-medium">
                    {{user?.name ? user.name : 'Usuário'}}
                </div>
                <div
                    class="w-full mt-0.5 whitespace-nowrap overflow-ellipsis overflow-hidden text-center text-md leading-normal font-medium ">
                    {{user?.email ? user.email : 'usuario@vivaintra.com'}}
                </div>
            </div>
        </div>

        <div class="pl-6 p-4">PAINEL PRINCIPAL</div>
    </ng-container>

    <!-- Navigation footer hook -->
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b print:hidden">
        <!-- Navigation toggle button -->
        <button
            mat-icon-button
            (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-2">
            <!--<search [appearance]="'bar'"></search>-->
            <notifications ></notifications>
            <user-menu [showAvatar]="false"></user-menu>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto bg-light">
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->

    <div class="h-20 grid grid-rows-1 grid-flow-col items-center  w-full md:px-6 border-t bg-light dark:bg-t print:hidden">
        <div>
            <small>v.2345678<br>Última atualização {{today | date:'dd/M/yyyy H:mm'}}</small>
        </div>
        <div class="text-center">
            <div>Siga-nos nas redes sociais<br>
                <div class="d-flex items-center pt-1">
                    <button mat-icon-button (click)="goToFace()"><mat-icon svgIcon="feather:facebook"></mat-icon></button>
                    <button mat-icon-button (click)="goToInsta()"><mat-icon svgIcon="feather:instagram"></mat-icon></button>
                    <button mat-icon-button (click)="goToLinkedin()"><mat-icon svgIcon="feather:linkedin"></mat-icon></button>
                    <button mat-icon-button (click)="goToYoutube()"><mat-icon svgIcon="feather:youtube"></mat-icon></button>
                </div>
            </div>
        </div>
        <div class="justify-self-end">
            <img class="w-40" src="assets/images/logo/vivaintra-logo.webp" alt="logo">
        </div>
      </div>
</div>
