/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'insight',
        title: 'Insight',
        type : 'collapsable',
        icon : 'heroicons_solid:trending-up',
        link : '/insight',
        children: [
            {
                id: 'insight-dashboard',
                title: 'Dashboard',
                type: 'basic',
                link : '/insight/dashboard'
            },
        ],
    },
    {
        id   : 'relatorios',
        title: 'Relatórios Gerenciais',
        type : 'collapsable',
        icon : 'heroicons_solid:document-text',
        link : '/relatorios',
        children: [
            {
                id   : 'relatoriosAcessos',
                title: 'Acessos por Módulo',
                type : 'basic',
                link : '/relatorios/log-acesso-modulos',
            },
        ]
    },
    {
        id   : 'app-comunicacao',
        title: 'Comunicação',
        type : 'basic',
        icon : 'heroicons_solid:annotation',
        link : '/../admin/globe/intra-empresa',
        externalLink: true
    },
    {
        id   : 'produtividade',
        title: 'Produtividade',
        type : 'basic',
        icon : 'groups',
        link : '/../admin/globe/minha-intra',
        externalLink: true
    },
    {
        id   : 'colaboracao',
        title: 'Colaboração',
        type : 'basic',
        icon : 'settings_applications',
        link : '/../admin/globe/intra-social',
        externalLink: true
    },
    {
        id   : 'recursos',
        title: 'Recursos Humanos',
        type : 'basic',
        icon : 'groups',
        link : '/../admin/globe/recursos-humanos',
        externalLink: true
    },
    {
        id   : 'extranet',
        title: 'Extranet',
        type : 'basic',
        icon : 'heroicons_outline:external-link',
        link : '/../admin/globe/extranet',
        externalLink: true
    },
    {
        id   : 'configuracoes',
        title: 'Configurações',
        type : 'basic',
        icon : 'settings',
        link : '/../admin/globe/configuracoes',
        externalLink: true
    },
];
